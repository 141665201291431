body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@function vw($px-vw, $base-vw: 1920px) {
  @return ($px-vw / $base-vw) * 100vw;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

::-webkit-scrollbar-thumb:hover {
  background: #a2a2a2;
}


a {
  all: unset;

  font-weight: bold;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }

  &:visited {
    color: inherit;
  }
}

button, button:active {
  all: unset;
}

*, *::before, *::after { box-sizing: inherit;}

html {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
